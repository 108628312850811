<template>
  <v-card>
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            Sessions
          </v-col>
        </v-row>
      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-1"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'SessionsTable',

  data () {
    return {
      headers: [
        { text: 'User', value: 'user_name', sortable: false },
        { text: 'IP (v4 / v6)', value: 'ip', sortable: false },
        { text: 'User agent', value: 'user_agent', sortable: false },
        { text: 'Created', value: 'created_at', sortable: false },
        { text: 'Last access', value: 'last_access_at', sortable: false },
        { text: 'Expires', value: 'expires_at', sortable: false },
      ],
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      lsOptions: 'sessions_options',
      sessionsEndpoint: '/a/system/sessions',
    }
  },

  mixins: [dataTablesMixin],

  watch: {
    options: {
      handler () {
        this.fetchSessionData()
      },
      deep: true,
    },
  },

  methods: {
    fetchSessionData: function () {
      this.loading = true
      var myURL = this.sessionsEndpoint + '?xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, [], ['id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchSessionData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>
